import { Routes } from '@angular/router';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientDetailsComponent } from './pages/client-details/client-details.component';

export const clientsRouter: Routes = [
  {
    path: '',
    component: ClientsComponent,
  },
  {
    path: 'details/:id',
    component: ClientDetailsComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];
