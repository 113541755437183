<div>
  @if (data) { @if (isArrayOfDates()) {
  <span>{{ data[0] | date : "dd/MM/yyyy" }}</span>
  <span> - </span>
  <span>{{ data[1] | date : "dd/MM/yyyy" }}</span>

   }@else{
  <span>{{ data | date : "dd/MM/yyyy" }}</span>
  } }@else{
  <span>-</span>
  }
</div>
