import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Login } from '../../models/login.model';
import { TResult } from '@core/models/TResult';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private URL_BASE = `${environment.apiAxzyUrl}/${environment.apiVersion}/user`;

  constructor(private http: HttpClient) {}

  login(data: Login): Promise<TResult<string>> {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'AXZY_KEY': environment.appKey
    });


    const request = this.http.post<TResult<string>>(
      `${this.URL_BASE}/login`,
      data,
      {
        headers,
      }
    );

    return firstValueFrom<TResult<string>>(request);
  }
}
