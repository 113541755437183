import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '@atoms/button/button.component';
import { DropdownComponent } from '@atoms/dropdown/dropdown.component';
import { InputComponent } from '@atoms/input/input.component';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-clients-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputComponent,
    DropdownComponent,
    ButtonComponent,
  ],
  templateUrl: './clients-form.component.html',
  styleUrl: './clients-form.component.scss',
})
export class ClientsFormComponent {
  public patientsForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    application: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    legalName: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    fiscalAddress: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    operativeAddress: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    delegation: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
    topic: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    organizationObject: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]),
  });

  public ref = inject(DynamicDialogRef);

  private instance: DynamicDialogComponent;

  private dialogService = inject(DialogService);

  constructor() {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    if (this.instance && this.instance.data) {
      const { data }: { data: any } = this.instance;
      this.patientsForm.patchValue({
        id: data.id,
        name: data.name,
        legalName: data.legalName,
        fiscalAddress: data.fiscalAddress,
        application: data.application,
        operativeAddress: data.operativeAddress,
        delegation: data.delegation,
        phoneNumber: data.phoneNumber,
        topic: data.topic,
        email: data.email,
        organizationObject: data.organizationObject,
      });
    }
  }

  public onSubmit() {
    this.ref.close(this.patientsForm.value);
  }
}
