import { computed, inject } from '@angular/core';
import { Dialog } from '@core/models/dialog.model';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';

type DialogState = {
  dialogResponse: any;
};

const initialState: DialogState = {
  dialogResponse: undefined,
};

export const DialogStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withComputed((store) => ({
    getDialogResponse: computed(() => store.dialogResponse()),
  })),
  withMethods((state) => {
    let dialogService = inject(DialogService);
    return {
      showDialog(component: any, dialogToShow: DynamicDialogConfig) {
        let ref = dialogService.open(component, dialogToShow);
        return ref;
      },
    };
  })
);
