import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

type loaderState = {
  isLoading: boolean;
};

const initialState: loaderState = {
  isLoading: false,
};

export const LoaderStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withMethods(({ isLoading, ...store }) => ({
    showLoader() {
      patchState(store, { isLoading: true });
    },
    hideLoader() {
      patchState(store, { isLoading: false });
    },
  }))
);
