import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-date',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './table-date.component.html',
  styleUrl: './table-date.component.scss',
})
export class TableDateComponent {
  @Input()
  data: any | null = null;

  isArrayOfDates(): boolean {
    return Array.isArray(this.data);
  }
}
