import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardComponent } from '@atoms/card/card.component';
import { IMenuItem } from 'app/core/models/menubar.model';

@Component({
  selector: 'app-home-card',
  standalone: true,
  imports: [CardComponent, RouterModule],
  templateUrl: './home-card.component.html',
  styleUrl: './home-card.component.scss',
})
export class HomeCardComponent {
  public item = input.required<IMenuItem>();
}
