import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '@atoms/button/button.component';
import { CardComponent } from '@atoms/card/card.component';
import { InputComponent } from '@atoms/input/input.component';
import { TResult } from '@core/models/TResult';
import { Auth } from '@core/models/auth.model';
import { AuthStore } from '@core/store/auth/auth.state';
import { ToastStore } from '@core/store/toast/toast.state';
import { TForm } from '@core/types/TForm.type';
import { Login } from '@modules/auth/core/models/login.model';
import { LoginService } from '@modules/auth/core/services/login/login.service';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    InputComponent,
    ButtonComponent,
    CardComponent,
    NgOptimizedImage,
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent {
  private authService = inject(LoginService);
  private toastStore = inject(ToastStore);
  private authStore = inject(AuthStore);
  public loginForm = new FormGroup<TForm<Login>>({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  async onSubmit() {
    const response = await this.authService
      .login(this.loginForm.value as Login)
      .then((data: TResult<string>) => {
        this.toastStore.showToast({
          severity: 'success',
          summary: 'Login',
          detail: 'Bienvenido!',
        });

        return data.data;
      })
      .catch((err) => {
        this.toastStore.showToast({
          severity: 'error',
          summary: 'Login',
          detail: 'Usuario o contraseña incorrectos',
        });
        return null;
      });

    if (!response) return;

    const decodedToken = jwtDecode<Auth>(response);

    this.authStore.setAuthUser({
      ...decodedToken,
      token: response,
    });
  }
}
