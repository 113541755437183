import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@atoms/button/button.component';
import { AuthStore } from '@core/store/auth/auth.state';
import { MENU_ITEMS } from 'app/core/constants/menuItems';
import { IMenuItem } from 'app/core/models/menubar.model';
import { MenubarModule } from 'primeng/menubar';
import { AvatarModule } from 'primeng/avatar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    MenubarModule,
    RouterModule,
    CommonModule,
    ButtonComponent,
    AvatarModule,
    OverlayPanelModule,
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
})
export class TopbarComponent {
  public authStore = inject(AuthStore);
  public menuItems: IMenuItem[] = MENU_ITEMS;
  public version: string = environment.apiVersion;
}
