import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private URL_BASE = `${environment.apiUrl}/${environment.apiVersion}/payments`;

  constructor(private http: HttpClient) {}

  async addPayment(payment: any): Promise<any> {
    const request = this.http.post(`${this.URL_BASE}`, payment);
    return firstValueFrom(request);
  }

  async updatePayment(id: string, payment: any): Promise<any> {
    const request = this.http.put(`${this.URL_BASE}/${id}`, payment);
    return firstValueFrom(request);
  }

  async deletePayment(id: string): Promise<any> {
    const request = this.http.delete(`${this.URL_BASE}/${id}`);
    return firstValueFrom(request);
  }

  async addPaymentDetail(id: string, payment: any): Promise<any> {
    const request = this.http.post(`${this.URL_BASE}/${id}/detail`, payment);
    return firstValueFrom(request);
  }
}
