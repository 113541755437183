<app-table
class="overflow-scroll overflow-y-hidden"

  [data]="payments()"
  [loading]="loading()"
  [headers]="headers()"
  [childrenHeaders]="childrenHeaders()"
  [childrenField]="'paymentDetail'"
  [showTableActions]="false"
  [isExpandable]="true"
>
</app-table>
