<app-toast></app-toast>
<div class="h-screen flex justify-content-center align-items-center">
  <div
    class="generator__shape_top_right"
    style="border-radius: 20% 80% 26% 74% / 75% 20% 80% 25% "
  ></div>
  <div
    class="generator__shape_bottom_left"
    style="border-radius: 20% 80% 26% 74% / 75% 20% 80% 25% "
  ></div>
  <app-login-form></app-login-form>
</div>
