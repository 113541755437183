import { Component, input, output, signal } from '@angular/core';
import { CardComponent } from '@atoms/card/card.component';
import { ClientInfoPaymentsComponent } from '../client-info-payments/client-info-payments.component';

@Component({
  selector: 'app-client-info',
  standalone: true,
  imports: [CardComponent, ClientInfoPaymentsComponent],
  templateUrl: './client-info.component.html',
  styleUrl: './client-info.component.scss',
})
export class ClientInfoComponent {
  public screenWidth = signal<number>(window.innerWidth);

  clientDetails = input.required<any>();
  loading = input.required<boolean>();
  onRefreshTable = output();
  onEditPayment = output();
  onAddPayment = output();
  onDeletePayment = output();
}
