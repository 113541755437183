import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

export const homeRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];
