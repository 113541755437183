import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-boolean',
  standalone: true,
  imports: [],
  templateUrl: './table-boolean.component.html',
  styleUrl: './table-boolean.component.scss',
})
export class TableBooleanComponent {
  @Input()
  data!: boolean;
}
