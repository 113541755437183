import { Component, inject, input, output, signal } from '@angular/core';
import { TableComponent } from '@atoms/table/table.component';
import { TableAction, TableHeader } from '@core/models/table.model';
import { DialogStore } from '@core/store/dialog/dialog.state';
import { ToastStore } from '@core/store/toast/toast.state';
import { TableActionsComponent } from 'app/shared/components/templates/table-actions/table-actions.component';
import { TableDateComponent } from 'app/shared/components/templates/table-date/table-date.component';

@Component({
  selector: 'app-client-info-payments',
  standalone: true,
  imports: [TableComponent],
  templateUrl: './client-info-payments.component.html',
  styleUrl: './client-info-payments.component.scss',
})
export class ClientInfoPaymentsComponent {
  payments = input.required<any[]>();
  loading = input.required<boolean>();
  onRefreshTable = output();
  onEditPayment = output();
  onAddPayment = output();
  onDeletePaymentHeader = output();
  dialogStore = inject(DialogStore);
  toastStore = inject(ToastStore);

  actions = signal<TableAction[]>([
    {
      icon: 'pi pi-wallet',
      type: 'button',
      severity: 'success',
      text: true,
      action: this.handleAddPayment.bind(this),
      validator: (data: any) => data.total == 0,
    },
    {
      icon: 'pi pi-pencil',
      type: 'button',
      severity: 'info',
      text: true,
      action: this.handleEditAction.bind(this),
      validator: (data: any) => data.paid > 0,
    },
    {
      icon: 'pi pi-trash',
      type: 'button',
      severity: 'danger',
      text: true,
      action: this.handleDeleteAction.bind(this),
      validator: (data: any) => data.paid > 0,
    },
  ]);

  headers = signal<TableHeader[]>([
    {
      field: 'invoiceNumber',
      header: 'Factura',
      type: 'text',
      options: {
        width: '100px',
        isGlobalFilter: true,
      },
    },
    {
      field: 'total',
      header: 'Total',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'paid',
      header: 'Total pagado',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'invoiceDate',
      header: 'Fecha de pago',
      type: 'component',
      component: TableDateComponent,
      options: {
        width: '100px',
      },
    },
    {
      field: 'actions',
      header: 'Acciones',
      type: 'component',
      inject: true,
      actions: this.actions(),
      component: TableActionsComponent,
      options: {
        width: '100px',
      },
    },
  ]);

  childrenHeaders = signal<TableHeader[]>([
    {
      field: 'concept',
      header: 'Concepto',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'amount',
      header: 'Cantidad',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'createdAt',
      header: 'Fecha de pago',
      type: 'component',
      component: TableDateComponent,
      options: {
        width: '100px',
      },
    },
  ]);

  handleEditAction(data: any) {
    this.onEditPayment.emit(data);
  }

  handleDeleteAction(data: any) {
    this.onDeletePaymentHeader.emit(data);
  }

  handleAddPayment(data: any) {
    this.onAddPayment.emit(data);
  }
}
