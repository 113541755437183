import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@atoms/button/button.component';
import { CardComponent } from '@atoms/card/card.component';
import { ToastComponent } from '@atoms/toast/toast.component';
import { AuthStore } from '@core/store/auth/auth.state';
import { TopbarComponent } from '@layout/topbar/topbar.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    TopbarComponent,
    RouterModule,
    CardComponent,
    ToastComponent,
    ButtonComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  public authStore = inject(AuthStore);
  public title = input.required<string>();
  public backLink = input<string>('');
}
