import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonComponent } from '@atoms/button/button.component';
import { CardComponent } from '@atoms/card/card.component';
import { PaymentsService } from '@core/services/payments/payments.service';
import { DialogStore } from '@core/store/dialog/dialog.state';
import { ToastStore } from '@core/store/toast/toast.state';
import { LayoutComponent } from '@layout/layout/layout.component';
import { ClientInfoComponent } from '@modules/clients/components/client-info/client-info.component';
import { ClientsService } from '@modules/clients/core/services/clients/clients.service';
import { PaymentsDetailsFormComponent } from 'app/shared/components/molecules/payments-details-form/payments-details-form.component';
import { PaymentsFormComponent } from 'app/shared/components/molecules/payments-form/payments-form.component';
import { DialogActionComponent } from 'app/shared/components/templates/dialog-action/dialog-action.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-client-details',
  standalone: true,
  imports: [
    LayoutComponent,
    CommonModule,
    ButtonComponent,
    CardComponent,
    ClientInfoComponent,
  ],
  templateUrl: './client-details.component.html',
  styleUrl: './client-details.component.scss',
})
export class ClientDetailsComponent implements OnInit {
  private clientService = inject(ClientsService);
  private paymentsService = inject(PaymentsService);
  private route = inject(ActivatedRoute);
  private toastStore = inject(ToastStore);
  private dialogStore = inject(DialogStore);
  public loading = signal<boolean>(false);
  clientDetails = signal<any>({});
  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.params['id'];
    if (!id) {
      this.toastStore.showToast({
        severity: 'error',
        summary: 'Operacion fallida',
        detail: 'id invalido',
      });
    }
    this.getClientDetails(id);
  }

  async getClientDetails(id: any) {
    this.loading.set(true);
    const response = await this.clientService.getClientById(id).finally(() => {
      this.loading.set(false);
    });
    console.log({ response });
    this.clientDetails.set(response.data);
  }

  async onAddPayment() {
    const ref = this.dialogStore.showDialog(PaymentsFormComponent, {
      data: null,
      header: 'Nuevo Pago',
      width: '50vw',
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      contentStyle: {
        overflow: 'auto',
      },
    });

    const response = await firstValueFrom(ref.onClose);
    console.log(response);
    if (!response) return;

    this.loading.set(true);
    this.paymentsService
      .addPayment({
        total: response.total,
        subTotal: response.total,
        paid: response.paid,
        invoiceDate: response.invoiceDate,
        invoiceNumber: response.invoiceNumber,
        clientId: this.clientDetails().id,
      })
      .finally(() => {
        this.getClientDetails(this.clientDetails().id);
      });
  }

  async onEditPayment(payment: any) {
    const ref = this.dialogStore.showDialog(PaymentsFormComponent, {
      data: payment,
      header: 'Editar Pago',
      width: '50vw',
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      contentStyle: {
        overflow: 'auto',
      },
    });

    const response = await firstValueFrom(ref.onClose);
    console.log(response);
    if (!response) return;

    this.loading.set(true);
    this.paymentsService
      .updatePayment(payment.id, {
        id: payment.id,
        total: response.total,
        subTotal: response.subTotal,
        paid: response.paid,
        invoiceDate: response.invoiceDate,
        invoiceNumber: response.invoiceNumber,
        clientId: this.clientDetails().id,
      })
      .finally(() => {
        this.getClientDetails(this.clientDetails().id);
      });
  }

  async onDeletePayment(payment: any) {
    console.log({ payment });
    const ref = this.dialogStore.showDialog(DialogActionComponent, {
      header: 'Estas seguro de eliminar a este pago?',
      width: '35vw',
      breakpoints: {
        '1280px': '45vw',
        '640px': '90vw',
      },
      contentStyle: {
        overflow: 'auto',
      },
    });

    const response = await firstValueFrom<boolean>(ref.onClose);

    if (!response)
      return this.toastStore.showToast({
        severity: 'info',
        summary: 'Operacion cancelada',
        detail: 'no se completo la eliminacion del pago',
      });

    await this.paymentsService
      .deletePayment(payment.id)
      .then(() => {
        this.toastStore.showToast({
          severity: 'success',
          summary: 'Pago eliminado',
          detail: 'El pago ha sido eliminado exitosamente',
        });
      })
      .catch(() => {
        this.toastStore.showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un error al eliminar el pago',
        });
      })
      .finally(() => this.getClientDetails(this.clientDetails().id));
  }

  async onAddPaymentDetail(payment: any) {
    const ref = this.dialogStore.showDialog(PaymentsDetailsFormComponent, {
      data: payment,
      header: 'Nuevo Pago',
      width: '50vw',
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      contentStyle: {
        overflow: 'auto',
      },
    });

    const response = await firstValueFrom(ref.onClose);
    console.log(response);
    if (!response) return;

    this.loading.set(true);
    this.paymentsService
      .addPaymentDetail(payment.id, {
        concept: response.concept,
        amount: response.amount,
      })
      .then(() => {
        this.toastStore.showToast({
          severity: 'success',
          summary: 'Pago agregado',
          detail: 'El pago ha sido agregado exitosamente',
        });
      })
      .catch(() => {
        this.toastStore.showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un error al agregar el pago',
        });
      })
      .finally(() => {
        this.getClientDetails(this.clientDetails().id);
      });
  }
}
