import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoaderStore } from '@core/store/loader/loader.state';
import { finalize } from 'rxjs';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loaderStore = inject(LoaderStore);

  loaderStore.showLoader();

  return next(req).pipe(
    finalize(() => {
      loaderStore.hideLoader();
    })
  );
};
