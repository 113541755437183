<form class="flex flex-column lg:flex-row lg:flex-wrap">
  <app-input
    class="col-12 lg:col-6"
    title="Nombre de la organización"
    placeholder="ASOCIACIÓN"
    type="text"
    [control]="patientsForm.controls.name"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Solicitud"
    placeholder="REGISTRO"
    type="text"
    [control]="patientsForm.controls.application"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Representante Legal"
    placeholder="JUAN PEREZ"
    type="text"
    [control]="patientsForm.controls.legalName"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Domicilio Fiscal"
    placeholder="CALLE 123"
    type="text"
    [control]="patientsForm.controls.fiscalAddress"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Domicilio Operativo"
    placeholder="CALLE 321"
    type="text"
    [control]="patientsForm.controls.operativeAddress"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Delegación"
    placeholder="TIJUANA"
    type="text"
    [control]="patientsForm.controls.delegation"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Teléfono"
    placeholder="1234567890"
    type="text"
    [control]="patientsForm.controls.phoneNumber"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Rubro"
    placeholder="EDUCACIÓN"
    type="text"
    [control]="patientsForm.controls.topic"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Correo Electrónico"
    placeholder="example@axzy.dev"
    type="text"
    [control]="patientsForm.controls.email"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Objetivo Social de la Organización"
    placeholder="AYUDAS"
    type="text"
    [control]="patientsForm.controls.organizationObject"
  />

  <app-button
    class="col-12"
    type="button"
    [disabled]="!patientsForm.valid"
    (onClick)="onSubmit()"
    label="Guardar"
    [raised]="true"
    [text]="true"
  />
</form>
