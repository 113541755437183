import { Component, input, output } from '@angular/core';
import { Severity } from '@core/types/severity.type';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  public label = input<string>();
  public onClick = output<MouseEvent>();
  public icon = input<string>();
  public type = input.required<string>();
  public severity = input<Severity>('primary');
  public raised = input<boolean>(false);
  public disabled = input<boolean>(false);
  public rounded = input<boolean>(false);
  public text = input<boolean>(false);
  public classes = input<string>('');
}
