import { Component, inject, OnInit, signal } from '@angular/core';
import { LayoutComponent } from '@layout/layout/layout.component';
import { ClientListComponent } from '../../components/client-list/client-list.component';
import { ClientsService } from '@modules/clients/core/services/clients/clients.service';

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [LayoutComponent, ClientListComponent],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss',
})
export class ClientsComponent  {
  private clientService = inject(ClientsService);

 
}
