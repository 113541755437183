<p-menubar [model]="menuItems">
  <ng-template pTemplate="start">
    <img
      src="assets/images/logo_without_circle.png"
      width="65"
      height="55"
      class="cursor-pointer mx-5"
      alt="Logo"
      [routerLink]="['/home']"
    />
  </ng-template>

  <ng-template pTemplate="item" let-item let-root="root">
    <a
      [routerLink]="item.route"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{ exact: true }"
      pRipple
      class="flex align-items-center p-menuitem-link"
    >
      <span
        style="font-size: 1.3rem; font-weight: bold; margin-bottom: 4px"
        [class]="item.icon"
      ></span>
      <span class="ml-2 text-xl">{{ item.label }}</span>
    </a>
  </ng-template>
  <ng-template pTemplate="end">
    <div
      class="flex flex-column justify-content-center align-items-center md:flex-row mx-5"
    >
      <p-avatar
        [label]="authStore.selectAvatarLetter()"
        size="large"
        shape="circle"
        class="cursor-pointer"
        (click)="overlayPanel.toggle($event)"
      ></p-avatar>
      <p-overlayPanel #overlayPanel>
        <div
          class="flex flex-column gap-2 justify-content-center align-items-center"
        >
          <span class="text-700">
            {{ authStore.selectUserName() }}
          </span>
          <app-button
            type="button"
            [text]="true"
            icon="pi pi-sign-out"
            (onClick)="authStore.clearAuthUser()"
          ></app-button>
          <span class="text-xs text-300">
            {{ version }}
          </span>
        </div>
      </p-overlayPanel>
    </div>
  </ng-template>
</p-menubar>
