import { Component, OnInit, inject, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '@atoms/button/button.component';
import { DropdownComponent } from '@atoms/dropdown/dropdown.component';
import { InputComponent } from '@atoms/input/input.component';
import { PaymentsService } from '@core/services/payments/payments.service';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-payments-details-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    DropdownComponent,
    ButtonComponent,
  ],
  templateUrl: './payments-details-form.component.html',
  styleUrl: './payments-details-form.component.scss',
})
export class PaymentsDetailsFormComponent implements OnInit {
  public paymentsForm = new FormGroup({
    concept: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required, Validators.min(1)]),
  });

  public paymentService = inject(PaymentsService);

  public ref = inject(DynamicDialogRef);
  private instance: DynamicDialogComponent;

  private dialogService = inject(DialogService);

  public paymentTypesOptions = signal<any[]>([]);

  public totalToPay = signal<number>(0);

  constructor() {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    if (this.instance && this.instance.data) {
      const { data }: { data: any } = this.instance;
      console.log({ data });
      this.totalToPay.set(data.subTotal);

      this.paymentsForm.controls.amount.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(this.totalToPay()),
      ]);
    }
  }

  public onSubmit() {
    this.ref.close(this.paymentsForm.value);
  }
}
