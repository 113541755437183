<form class="flex flex-column lg:flex-row lg:flex-wrap">
  <app-input
    class="col-12 lg:col-6"
    title="Concepto"
    placeholder="A1234"
    type="text"
    [control]="paymentsForm.controls.invoiceNumber"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Total"
    placeholder="0.00"
    type="number"
    [control]="paymentsForm.controls.total"
  />

  <app-input
    class="col-12 lg:col-6"
    title="Fecha pago"
    type="date"
    placeholder="dd/mm/yyyy"
    [control]="paymentsForm.controls.invoiceDate"
  />

  <app-button
    class="col-12"
    type="button"
    [disabled]="!paymentsForm.valid"
    (onClick)="onSubmit()"
    label="Guardar"
    [raised]="true"
    [text]="true"
  />
</form>
