<form
  [formGroup]="loginForm"
  (ngSubmit)="onSubmit()"
  class="flex flex-column justify-content-center lg:flex-row lg:flex-wrap m-3 max-w-30rem"
>
  <div
    class="flex justify-content-center align-items-center cursor-pointer col-12"
  >
    <img
      ngSrc="assets/images/image.png"
      class="w-3"
      width="100"
      height="100"
      alt="Logo"
    />
  </div>

  <p class="text-4xl font-bold text-gray-700 text-center">Iniciar de sesion</p>

  <app-input
    class="col-12"
    title="Email"
    placeholder="example@axzy.dev"
    type="text"
    textClass="text-xl font-medium text-gray-700"
    [control]="loginForm.controls.email"
  />

  <app-input
    class="col-12"
    title="Contraseña"
    placeholder="xxxxxxxxxx"
    textClass="text-xl font-medium text-gray-700"
    type="password"
    [control]="loginForm.controls.password"
  />

  <app-button
    class="col-12"
    type="submit"
    [severity]="'info'"
    [disabled]="!loginForm.valid"
    label="Iniciar sesion"
    [raised]="true"
  />
</form>
