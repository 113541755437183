<app-card
  className="my-5 flex justify-content-center align-items-center w-25rem h-15rem hover:bg-gray-200"
>
  <div
    class="flex flex-column justify-content-center align-items-center gap-1"
    [routerLink]="item().route"
  >
    <i [class]="'text-8xl ' + item().icon"></i>
    <a class="text-3xl font-bold">{{ item().label }}</a>
  </div>
</app-card>
