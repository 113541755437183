<div class="flex justify-content-center flex-wrap flex-row gap-3 p-5">
  <app-card
    class="w-full"
    className="flex justify-content-center align-items-center bg-blue-50 "
  >
    <h1 class="text-cyan-900" header>Detalles generales</h1>

    <div class="grid m-5 column-gap-0 m-0 p-0 w-full">
      <div class="grid col-12 m-0 p-0">
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Nombre:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().name }}
            </p>
          </div>
        </div>
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Solicitud:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().application }}
            </p>
          </div>
        </div>
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Representante Legal:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().legalName }}
            </p>
          </div>
        </div>
      </div>
      <div class="grid col-12 m-0 p-0">
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Domicilio Fiscal:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().fiscalAddress }}
            </p>
          </div>
        </div>
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Domicilio Operativo:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().operativeAddress }}
            </p>
          </div>
        </div>
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Delegación:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().delation }}
            </p>
          </div>
        </div>
      </div>
      <div class="grid col-12 m-0 p-0">
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Telefono:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().phoneNumber }}
            </p>
          </div>
        </div>
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Rubro:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().topic }}
            </p>
          </div>
        </div>
        <div class="col-12 lg:col-4 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Correo Electrónico:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().email }}
            </p>
          </div>
        </div>
      </div>
      <div class="grid col-12 m-0 p-0">
        <div class="col-12 m-0 p-0">
          <div class="p-2 grid h-fit">
            <p class="text-left col m-0 text-lg font-semibold text-cyan-900">
              Objetivo social de la organización:
            </p>
            &nbsp;
            <p class="text-center lg:text-left col m-0 text-lg">
              {{ clientDetails().organizationObject }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-card>
  <app-card
    class="overflow-scroll overflow-y-hidden w-full"
    [width]="screenWidth() > 1024 ? '100%' : 'max-content'"
    className="flex justify-content-center align-items-center bg-blue-50 w-full"
  >
    <h1 class="text-cyan-900" header>Pagos</h1>
    <app-client-info-payments
      class="w-full"
      (onAddPayment)="onAddPayment.emit($event)"
      (onDeletePaymentHeader)="onDeletePayment.emit($event)"
      (onEditPayment)="onEditPayment.emit($event)"
      (onRefreshTable)="onRefreshTable.emit()"
      [payments]="clientDetails().payments"
      [loading]="loading()"
    ></app-client-info-payments>
  </app-card>
</div>
