<div class="flex flex-row justify-content-between align-content-center py-2">
  <app-button
    type="button"
    (onClick)="ref.close(false)"
    label="Cancelar"
    severity="danger"
    [raised]="true"
    [text]="true"
  />
  <app-button
    type="button"
    (onClick)="ref.close(true)"
    label="Confirmar"
    [raised]="true"
    [text]="true"
  />
</div>
