import { Routes } from '@angular/router';

import { homeRoutes } from './modules/home/home.routes';
import { authRoutes } from './modules/auth/auth.routes';
import { authGuard } from '@core/guards/auth/auth.guard';
import { clientsRouter } from './modules/clients/clients.routes';

export const routes: Routes = [
  {
    path: 'home',
    canActivate: [authGuard],
    loadChildren: () => homeRoutes,
  },
  {
    path: 'auth',
    loadChildren: () => authRoutes,
  },
  {
    path: 'clients',
    loadChildren: () => clientsRouter,
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];
