<div class="flex flex-column gap-2">
  <label [class]="textClass()" [for]="title()">{{ title() }}</label>

  @switch (type()) { @case ('date') {
  <p-calendar
    appendTo="body"
    [id]="title()"
    [name]="title()"
    [disabled]="disabled()"
    [formControl]="control()"
    [placeholder]="placeholder()"
    [pKeyFilter]="getKeyFilter(type())"
    dateFormat="dd/mm/yy"
    [iconDisplay]="'input'"
    [showIcon]="true"
    (ngModelChange)="onInputChange.emit($event)"
    [class]="
      (control().valid ? '' : 'ng-invalid ng-dirty ') +
      (disabled() ? 'bg-gray-200' : '')
    "
  />
  } @case ('password') {
  <p-password
    [id]="title()"
    [placeholder]="placeholder()"
    [feedback]="false"
    [formControl]="control()"
    [toggleMask]="true"
    [pKeyFilter]="getKeyFilter(type())"
    (ngModelChange)="onInputChange.emit($event)"
    [class]="
      'p-password-input ' +
      (control().valid ? '' : 'ng-invalid ng-dirty ') +
      (disabled() ? 'bg-gray-200' : '')
    "
  />
  } @default {
  <input
    pInputText
    [id]="title()"
    [name]="title()"
    [readOnly]="disabled()"
    [placeholder]="placeholder()"
    [formControl]="control()"
    [pKeyFilter]="getKeyFilter(type())"
    (ngModelChange)="onInputChange.emit($event)"
    [class]="
      (control().valid ? '' : 'ng-invalid ng-dirty ') +
      (disabled() ? 'bg-gray-200' : '')
    "
  />
  } } @if(helpText()){
  <small [id]="title() + '-help'"> {{ helpText() }} </small>
  } @if(!disabled() && control().touched && !control().valid){
  <small class="text-red-500"> El campo {{ title() }} es requerido </small>
  }
</div>
