import { computed, effect, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@core/models/auth.model';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { JwtHelperService } from '@auth0/angular-jwt';

type AuthState = {
  auth: Auth;
};

const initialState: AuthState = {
  auth: {
    uuid: '',
    mandt: '',
    username: '',
    status: 'INACTIVE',
    iat: 0,
    exp: 0,
    token: '',
  },
};

export const AuthStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withComputed(({ auth }) => ({
    selectUserName: computed(() => auth().username),
    selectToken: computed(() => auth().token),
    selectAvatarLetter: computed(() => auth().username.charAt(0)),
  })),
  withMethods((store, router = inject(Router)) => ({
    setAuthUser(auth: Auth) {
      patchState(store, { auth: auth });
      router.navigate(['/home']);
    },
    clearAuthUser() {
      patchState(store, { auth: initialState.auth });
      router.navigate(['/auth']);
    },
  })),
  withHooks({
    onInit(store) {
      const auth = localStorage.getItem('authStore');
      const helper = new JwtHelperService();
      if (!auth) return;

      const authStore = JSON.parse(auth) as Auth;

      if (!authStore.token) return;

      const isTokenExpired = helper.isTokenExpired(authStore.token);

      if (isTokenExpired) {
        store.clearAuthUser();
        return;
      }
      store.setAuthUser(authStore);

      localStorage.clear();
    },
  })
);
