<div class="w-max flex flex-row justify-content-between">
  @for (action of data.actions; track $index) {
  <app-button
    [label]="action.label"
    [type]="action.type"
    [severity]="action.severity"
    [icon]="action.icon"
    [raised]="action.raised ?? false"
    [disabled]="action.disabled ?? false"
    [rounded]="action.rounded ?? false"
    [text]="action.text ?? false"
    (onClick)="action.action(data.rowData)"
  />
  }
</div>
