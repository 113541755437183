<app-layout title="Detalles del paciente" backLink="/clients">
    <app-button
      header
      class="m-5 md:m-1"
      (onClick)="onAddPayment()"
      [text]="true"
      type="button"
      label="Agregar pago"
    />
    <app-client-info
      (onEditPayment)="onEditPayment($event)"
      (onAddPayment)="onAddPaymentDetail($event)"
      (onDeletePayment)="onDeletePayment($event)"
      (onRefreshTable)="getClientDetails(clientDetails().id)"
      [clientDetails]="clientDetails()"
      [loading]="loading()"
    ></app-client-info>
</app-layout>
