import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ButtonComponent } from '@atoms/button/button.component';
import { TableAction } from '@core/models/table.model';

@Component({
  selector: 'app-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
  standalone: true,
  imports: [ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableActionsComponent implements OnChanges, AfterViewInit {
  @Input()
  data!: {
    rowData: any;
    actions: TableAction[];
  };

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
      this.updateActions();
    }
  }

  ngAfterViewInit(): void {
    this.updateActions();
  }

  private updateActions(): void {
    if (!this.data || !this.data.actions || !this.data.actions.length) {
      return;
    }
    const updatedActions = this.data.actions.map(action => {
      if (action.validator) {
        return {
          ...action,
          disabled: action.validator(this.data.rowData),
        };
      }
      return action;
    });

    this.data.actions = updatedActions;
    this.cdr.detectChanges();
  }
}
