<form class="flex flex-column lg:flex-row lg:flex-wrap">
  <app-input
    class="col-12 lg:col-6"
    title="Factura"
    type="text"
    [control]="paymentsForm.controls.concept"
  />
  <app-input
    class="col-12 lg:col-6"
    title="Cantidad"
    placeholder="0.00"
    type="number"
    [control]="paymentsForm.controls.amount"
    [helpText]="'Total restante: ' + totalToPay()"
  />

  <app-button
    class="col-12"
    type="button"
    [disabled]="!paymentsForm.valid"
    (onClick)="onSubmit()"
    label="Guardar"
    [raised]="true"
    [text]="true"
  />
</form>
