<p-button
  [label]="label()"
  [type]="type()"
  [class]="classes()"
  [severity]="severity()"
  (click)="onClick.emit($event)"
  [disabled]="disabled()"
  [raised]="raised()"
  [rounded]="rounded()"
  [text]="text()"
  size="small"
>
  @if(icon()){
  <i [class]="classes() + ' ' + icon()"></i>
  }
</p-button>
