import { Component, inject } from '@angular/core';
import { ButtonComponent } from '@atoms/button/button.component';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-action',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './dialog-action.component.html',
  styleUrl: './dialog-action.component.scss',
})
export class DialogActionComponent {
  public ref = inject(DynamicDialogRef);
}
