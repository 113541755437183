<p-table
  #table
  [value]="data()"
  [rows]="10"
  scrollHeight="500px"
  columnResizeMode="expand"
  [paginator]="true"
  [scrollable]="true"
  styleClass="p-datatable-sm"
  [globalFilterFields]="globalFilters()"
  [rowHover]="true"
  [rowsPerPageOptions]="[5, 10, 20]"
  dataKey="id"
  [resizableColumns]="true"
  [autoLayout]="true"
>
  @if(showTableActions()){
  <ng-template pTemplate="caption">
    <div
      class="flex flex-column justify-content-center align-items-center gap-2 md:flex-row md:justify-content-between mt-1 md:mt-3"
    >
      <div class="flex flex-row gap-2">
        @for (action of tableHeaderActions(); track $index) {
        <app-button
          [label]="action.label"
          [type]="action.type"
          [severity]="action.severity"
          [icon]="action.icon"
          [raised]="action.raised ?? false"
          [disabled]="action.disabled ?? false"
          [rounded]="action.rounded ?? false"
          [text]="action.text ?? false"
          (onClick)="action.action($event)"
        />
        }
      </div>
      <p-iconField>
        <p-inputIcon>
          <i class="pi pi-search"></i>
        </p-inputIcon>
        <input
          pInputText
          type="text"
          #globalFilter
          (input)="table.filterGlobal(globalFilter.value, 'contains')"
          placeholder="Busqueda global"
        />
      </p-iconField>
    </div>
  </ng-template>

  }

  <ng-template pTemplate="header" let-columns>
    <tr>
      @if(isExpandable()){
      <th style="width: 5rem"></th>
      } @for (col of headers(); track $index) {
      <th pResizableColumn [style]="'width:' + col.options?.width || '100px'">
        {{ col.header }}
      </th>
      }
    </tr>
  </ng-template>

  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
    let-expanded="expanded"
  >
    <tr>
      @if(isExpandable()){
      <td>
        <app-button
          type="button"
          pRipple
          [pRowToggler]="rowData"
          [text]="true"
          [rounded]="true"
          classes="m-0 p-0 text-sm"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        />
      </td>
      } @for (col of headers(); track $index) {
      <td
        class="flex-1 gap-3"
        [style]="
          'display:' + col.options?.display ||
          '' + ' width:' + col.options?.width ||
          '100px'
        "
      >
        @switch (col.type) { @case('actions'){ @for (action of col.actions;
        track $index) {
        <app-button
          [label]="action.label"
          [type]="action.type"
          [severity]="action.severity"
          [icon]="action.icon"
          [raised]="action.raised ?? false"
          [disabled]="action.disabled ?? false"
          [rounded]="action.rounded ?? false"
          [text]="action.text ?? false"
          (onClick)="action.action(rowData)"
        />
        } } @case ('component') { @if (col.component) {
        <ng-container
          *ngComponentOutlet="
            col.component;
            inputs: {
              data: col.inject
                ? {
                    actions: col.actions,
                    rowData: rowData
                  }
                : rowData[col.field]
            }
          "
        ></ng-container>
        }@else{
        <span>{{ rowData[col.field] }}</span>
        } } @default { <span>{{ rowData[col.field] }}</span> }}
      </td>
      }
    </tr>
  </ng-template>

  @if(isExpandable()){
  <ng-template pTemplate="rowexpansion" let-rowData>
    <tr>
      <td colspan="12">
        <p-table
          [value]="rowData[childrenField() ?? '']"
          dataKey="id"
          styleClass="p-datatable-gridlines p-datatable-sm p-datatable-wrapper"
          [rows]="10"
          scrollHeight="500px"
          frozenWidth="200px"
          [resizableColumns]="true"
          [scrollable]="true"
          [rowHover]="true"
          [autoLayout]="true"
        >
          <ng-template pTemplate="header">
            <tr>
              @for (col of childrenHeaders(); track $index) {
              <th
                pResizableColumn
                [style]="'width:' + col.options?.width || '100px'"
              >
                {{ col.header }}
              </th>
              }
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
            let-rowIndex="rowIndex"
            let-expanded="expanded"
          >
            <tr>
              @for (col of childrenHeaders(); track $index) {
              <td
                class="flex-1 gap-3"
                [style]="
                  'display:' + col.options?.display ||
                  '' + ' width:' + col.options?.width ||
                  '100px'
                "
              >
                @switch (col.type) { @case('actions'){ @for (action of
                col.actions; track $index) {
                <app-button
                  [label]="action.label"
                  [type]="action.type"
                  [severity]="action.severity"
                  [icon]="action.icon"
                  [raised]="action.raised ?? false"
                  [disabled]="action.disabled ?? false"
                  [rounded]="action.rounded ?? false"
                  [text]="action.text ?? false"
                  [classes]="action.classes ?? ''"
                  (onClick)="action.action(rowData)"
                />
                } } @case ('component') { @if (col.component) {
                <ng-container
                  *ngComponentOutlet="
                    col.component;
                    inputs: {
                      data: col.inject
                        ? {
                            actions: col.actions,
                            rowData: rowData
                          }
                        : rowData[col.field]
                    }
                  "
                ></ng-container>
                }@else{
                <span>{{ rowData[col.field] }}</span>
                } } @default { <span>{{ rowData[col.field] }}</span> }}
              </td>
              }
            </tr>
          </ng-template>
        </p-table>
      </td>
    </tr>
  </ng-template>
  }
</p-table>
