import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { ButtonComponent } from '@atoms/button/button.component';
import { CardComponent } from '@atoms/card/card.component';
import { TableAction, TableHeader } from '@core/models/table.model';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    ButtonComponent,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    CardComponent,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent {
  public data = input.required<any[]>();
  public loading = input.required<boolean>();
  public headers = input.required<TableHeader[]>();
  public tableHeaderActions = input<TableAction[]>();
  public showTableActions = input<boolean>(true);
  public isExpandable = input<boolean>(false);
  public childrenField = input<string>();
  public childrenHeaders = input<TableHeader[]>();

  //map headers to get the fields that are global filters
  protected globalFilters = computed(() => {
    const filters = this.headers().filter(
      (header) => header.options?.isGlobalFilter
    );
    return filters.map((filter) => filter.field);
  });
}
