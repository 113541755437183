<app-toast></app-toast>
<div class="flex flex-column h-screen overflow-hidden">
  <app-topbar />
  <div class="flex flex-1 flex-column overflow-hidden mx-4">
    <app-card
      class="m-3 border-1 border-gray-300 border-round-sm"
      className="py-5"
    >
      <div class="flex flex-column md:flex-row justify-content-between">
        <div
          class="flex flex-column md:flex-row align-items-center justify-content-center lg:justify-content-start ml-0 md:ml-3"
        >
          @if(backLink() != ''){
          <app-button
            type="button"
            classes="text-2xl text-black-alpha-90 py-1"
            [routerLink]="backLink()"
            icon="pi pi-arrow-left"
            [rounded]="true"
            [text]="true"
          >
          </app-button>
          }
          <a
            header
            class="text-2xl font-medium text-center ml-2 lg:text-left"
            >{{ title() }}</a
          >
        </div>
        <div class="mr-7 md:mr-5">
          <ng-content select="[header]"></ng-content>
        </div>
      </div>
    </app-card>
    <app-card
      class="mx-3 border-1 border-gray-300 border-round-sm h-screen mb-5 md:h-auto md:mb-3 overflow-auto"
    >
      <ng-content></ng-content>
    </app-card>
  </div>
</div>
