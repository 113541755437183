import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { ToastStore } from '@core/store/toast/toast.state';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { asapScheduler } from 'rxjs';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [ToastModule, CommonModule],
  providers: [MessageService],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  public store = inject(ToastStore);

  private toastService = inject(MessageService);
  public computedToast = computed(() => {
    const toast = this.store.toast();
    if (!toast.severity) return null;
    this.toastService.add({
      severity: toast.severity as string | undefined,
      summary: toast.summary,
      detail: toast.detail,
      life: toast.options?.duration,
    });
    asapScheduler.schedule(() =>
      this.store.showToast({
        severity: undefined,
        detail: '',
        summary: '',
      })
    );

    return toast;
  });
}
