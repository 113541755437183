import { Component } from '@angular/core';
import { LayoutComponent } from '@layout/layout/layout.component';
import { HomeCardComponent } from '@modules/home/components/home-card/home-card.component';
import { MENU_ITEMS } from 'app/core/constants/menuItems';
import { IMenuItem } from 'app/core/models/menubar.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [LayoutComponent, HomeCardComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  public menuItems: IMenuItem[] = MENU_ITEMS;
}
