import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthStore } from '@core/store/auth/auth.state';
import { JwtHelperService } from '@auth0/angular-jwt';

export const authGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const authStore = inject(AuthStore);
  const helper = new JwtHelperService();
  const token = await authStore.selectToken();

  if (!token) {
    router.navigate(['/auth']);
  }

  const isTokenExpired = helper.isTokenExpired(token);

  if (isTokenExpired) {
    router.navigate(['/auth']);
  }

  return true;
};
