import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Input } from '@core/types/input.types';
import { KeyFilterModule, KeyFilterPattern } from 'primeng/keyfilter';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { PasswordModule } from 'primeng/password';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
    PasswordModule,
    KeyFilterModule,
  ],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent{
  title = input.required<string>();
  type = input.required<Input>();
  control = input.required<FormControl>();
  placeholder = input<string>('');
  helpText = input<string>();
  disabled = input<boolean>(false);
  onInputChange = output<any>();
  textClass = input<string>('');

  getKeyFilter(type: Input): RegExp | KeyFilterPattern | null | undefined {
    let keyfilter: any = '';
    switch (type) {
      case 'number':
        keyfilter = 'num';
        break;
      default:
        break;
    }
    return keyfilter;
  }
}
