import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  private http = inject(HttpClient);

  private URL_BASE = `${environment.apiUrl}/${environment.apiVersion}/clients`;

  getAllClients(): Promise<any> {
    const request$ = this.http.get(this.URL_BASE);

    return firstValueFrom(request$);
  }

  getClientById(id: string): Promise<any> {
    const request$ = this.http.get(`${this.URL_BASE}/${id}`);

    return firstValueFrom(request$);
  }

  createClient(client: any): Promise<any> {
    const request$ = this.http.post(this.URL_BASE, client);

    return firstValueFrom(request$);
  }

  updateClient(id: string, client: any): Promise<any> {
    const request$ = this.http.put(`${this.URL_BASE}/${id}`, client);

    return firstValueFrom(request$);
  }

  deleteClient(id: string): Promise<any> {
    const request$ = this.http.delete(`${this.URL_BASE}/${id}`);

    return firstValueFrom(request$);
  }
}
