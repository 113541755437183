import { Component, inject, input, OnInit, signal } from '@angular/core';
import { TableComponent } from '@atoms/table/table.component';
import { TableAction, TableHeader } from '@core/models/table.model';
import { DialogStore } from '@core/store/dialog/dialog.state';
import { Severity } from '@core/types/severity.type';
import { ClientsFormComponent } from '../clients-form/clients-form.component';
import { firstValueFrom } from 'rxjs';
import { ClientsService } from '@modules/clients/core/services/clients/clients.service';
import { ToastStore } from '@core/store/toast/toast.state';
import { Router } from '@angular/router';
import { DialogActionComponent } from 'app/shared/components/templates/dialog-action/dialog-action.component';
import { TableBooleanComponent } from 'app/shared/components/templates/table-boolean/table-boolean.component';

@Component({
  selector: 'app-client-list',
  standalone: true,
  imports: [TableComponent],
  templateUrl: './client-list.component.html',
  styleUrl: './client-list.component.scss',
})
export class ClientListComponent implements OnInit {
  clientService = inject(ClientsService);
  loading = signal(false);
  clients = signal<any[]>([]);
  dialogStore = inject(DialogStore);
  toastStore = inject(ToastStore);
  router = inject(Router);
  public tableHeaderActions = signal<TableAction[]>([
    {
      label: 'Agregar Cliente',
      type: 'button',
      severity: 'success' as Severity,
      action: this.onAddNewClient.bind(this),
      text: true,
    },
  ]);

  public tableActions = signal<TableAction[]>([
    {
      icon: 'pi pi-eye',
      type: 'button',
      severity: 'info' as Severity,
      action: this.onRowSelect.bind(this),
      text: true,
    },
    {
      icon: 'pi pi-pencil',
      type: 'button',
      severity: 'secondary' as Severity,
      action: this.onEditClient.bind(this),
      text: true,
    },
    {
      icon: 'pi pi-trash',
      type: 'button',
      severity: 'danger' as Severity,
      action: this.onDeleteClient.bind(this),
      text: true,
    },
  ]);

  headers = signal<TableHeader[]>([
    {
      field: 'name',
      header: 'Nombre de la organizacion',
      type: 'text',
      options: {
        width: '100px',
        isGlobalFilter: true,
      },
    },
    {
      field: 'application',
      header: 'Solicitud',
      type: 'text',
      options: {
        width: '100px',
        isGlobalFilter: true,
      },
    },
    {
      field: 'legalName',
      header: 'Representante Legal',
      type: 'text',
      options: {
        width: '100px',
        isGlobalFilter: true,
      },
    },
    {
      field: 'delegation',
      header: 'Delegacion',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'phoneNumber',
      header: 'Telefono',
      type: 'text',
      options: {
        width: '100px',
        isGlobalFilter: true,
      },
    },
    {
      field: 'topic',
      header: 'Rubro',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'email',
      header: 'Correo Electronico',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'organizationObject',
      header: 'Objeto social de la organizacion',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'total',
      header: 'Total',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'totalPaid',
      header: 'Abonado',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'totalPendingToPaid',
      header: 'Pendiente de pago',
      type: 'text',
      options: {
        width: '100px',
      },
    },
    {
      field: 'isPaid',
      header: 'Completado',
      type: 'component',
      component: TableBooleanComponent,
      options: {
        width: '100px',
      },
    },
    {
      field: 'actions',
      header: 'Acciones',
      type: 'actions',
      actions: this.tableActions(),
      options: {
        width: 'auto',
        display: 'flex',
      },
    },
  ]);

  ngOnInit(): void {
    this.getAllClients();
  }

  async getAllClients(): Promise<void> {
    this.loading.set(true);
    const clients = await this.clientService
      .getAllClients()
      .finally(() => this.loading.set(false));

    this.clients.set(clients.data);
  }

  async onAddNewClient() {
    const ref = this.dialogStore.showDialog(ClientsFormComponent, {
      data: null,
      header: 'Nuevo Cliente',
      width: '50vw',
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      contentStyle: {
        overflow: '100px',
      },
    });

    const response = await firstValueFrom(ref.onClose);
    console.log(response);
    if (!response) return;

    await this.clientService
      .createClient({
        name: response.name,
        application: response.application,
        legalName: response.legalName,
        fiscalAddress: response.fiscalAddress,
        operativeAddress: response.operativeAddress,
        delegation: response.delegation,
        phoneNumber: response.phoneNumber,
        topic: response.topic,
        email: response.email,
        organizationObject: response.organizationObject,
      })
      .then(() => {
        this.toastStore.showToast({
          severity: 'success',
          summary: 'Cliente creado',
          detail: 'El cliente ha sido creado exitosamente',
        });
      })
      .catch(() => {
        this.toastStore.showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un error al crear el cliente',
        });
      })
      .finally(() => this.getAllClients());
  }

  async onEditClient(client: any) {
    console.log(client);
    const ref = this.dialogStore.showDialog(ClientsFormComponent, {
      data: client,
      header: 'Editar Cliente',
      width: '50vw',
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      contentStyle: {
        overflow: '100px',
      },
    });

    const response = await firstValueFrom(ref.onClose);
    if (!response) return;

    await this.clientService
      .updateClient(client.id, {
        name: response.name,
        application: response.application,
        legalName: response.legalName,
        fiscalAddress: response.fiscalAddress,
        operativeAddress: response.operativeAddress,
        delegation: response.delegation,
        phoneNumber: response.phoneNumber,
        topic: response.topic,
        email: response.email,
        organizationObject: response.organizationObject,
      })
      .then(() => {
        this.toastStore.showToast({
          severity: 'success',
          summary: 'Cliente actualizado',
          detail: 'El cliente ha sido actualizado exitosamente',
        });
      })
      .catch(() => {
        this.toastStore.showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un error al actualizar el cliente',
        });
      })
      .finally(() => this.getAllClients());
  }

  async onDeleteClient(client: any) {
    const ref = this.dialogStore.showDialog(DialogActionComponent, {
      header: 'Estas seguro de eliminar a este cliente?',
      width: '35vw',
      breakpoints: {
        '1280px': '45vw',
        '640px': '90vw',
      },
      contentStyle: {
        overflow: '100px',
      },
    });

    const response = await firstValueFrom<boolean>(ref.onClose);

    if (!response)
      return this.toastStore.showToast({
        severity: 'info',
        summary: 'Operacion cancelada',
        detail: 'no se completo la eliminacion del cliente',
      });

    await this.clientService
      .deleteClient(client.id)
      .then(() => {
        this.toastStore.showToast({
          severity: 'success',
          summary: 'Cliente eliminado',
          detail: 'El cliente ha sido eliminado exitosamente',
        });
      })
      .catch(() => {
        this.toastStore.showToast({
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un error al eliminar el cliente',
        });
      })
      .finally(() => this.getAllClients());
  }

  onRowSelect(event: any) {
    this.router.navigate(['clients/details', event.id]);
  }
}
