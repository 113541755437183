import { Toast } from '@core/models/toast.model';
import {
  patchState,
  signalStore,
  withMethods,
  withState
} from '@ngrx/signals';

type ToastState = {
  toast: Toast;
};

const initialState: ToastState = {
  toast: {
    severity: undefined,
    summary: '',
    detail: '',
    options: {
      duration: 1000,
    },
  },
};

export const ToastStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withMethods(({ toast, ...store }) => ({
    showToast(toastToShow: Toast) {
      patchState(store, { toast: toastToShow });
    },
  }))
);
